import React from 'react'
import './C1.css'
import Fade from 'react-reveal/Fade';


import Component1 from '../../assets/c1.webp'


const C1 = () => {
  return (
<>
    <div className='c1'>
        <div className='c1-flex container'>

        <Fade left>
            <div className="c1-left">
                <h2>WHO WE ARE</h2><br/>
                <h1>Restore Your Mind, Reclaim Your Life</h1>  
                <br/>  
                <p>Natural Rest House Treatment Center offers safe, medically supervised detox programs for individuals struggling with drug and alcohol addiction.</p>  
                <br/>  
                <p>To provide personalized and effective care, our drug and alcohol detox center conducts comprehensive assessments before treatment begins.  
                <br/><br/>  
                These evaluations help us understand your substance use history, co-occurring mental health disorders, past addiction treatments, and individual recovery needs. Our goal is to create a customized detox plan to help you achieve long-term sobriety.</p>

                <br />
            </div>
        </Fade>

        <Fade right>
            <div className="c1-right">
                <img src={Component1} alt="Psychologogist" loading="lazy" />
            </div>
        </Fade>
    </div>

    </div>

</>
  )
}

export default C1